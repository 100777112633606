import React from 'react';

import Layout from '../components/layout';
import SEO from '../components/seo';

const AboutPage = () => (
  <Layout>
    <SEO title="EasyBusy: About" />

    <div className={'page-header'}>
      <h2>What’s EasyBusy?</h2>
      <p>
        EasyBusy is a productivity app that will help you keep all your tasks and
        ideas in one place, complete them on time and never forget anything, all that
        will fun and no stress!
      </p>
    </div>

    <div className={'container'}>
      <div className={'features'}>
        <ul>
          <li>
            Quickly capture all your ideas and tasks in one place, and then organize
            them when you have time!
          </li>
          <li>
            You don't have to schedule tasks for certain dates, use flexible Next
            list to store tasks that you want to done soon, and Focus list for tasks
            that you're working on right now
          </li>
          <li>
            When you have some time to work, no matter if it's 15 minutes or 5 hours,
            use a convenient tool to find tasks that meet your current time and
            energy level
          </li>
          <li>
            Turn tasks into nice looking and easy to use documents with checklists
            and organized structure
          </li>
          <li>
            Separate tasks and projects into different contexts to help you focus.
            Keep your work, home, hobbies in different workspaces and focus only on
            one at a time.
          </li>
          <li>Flexible scheduling with Agenda calendar and Repeating tasks</li>
          <li>
            Organize tasks using Projects, Tags, each of them can be tuned into a
            document as well
          </li>
          <li>
            See a big picture and plan your long term goals using a beautiful
            Timeline view
          </li>
          <li>
            Earn achievements for completing or organizing tasks, making productivity
            so much fun!
          </li>
          <li>
            Synchronize your tasks across multiple devices and computers with our
            Premium Access.
          </li>
        </ul>
      </div>
    </div>
  </Layout>
);

export default AboutPage;
